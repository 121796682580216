<template>
  <h4>Sorry, you don't have access to this page</h4>
</template>

<script>
export default {
  name: "NotAllowed"
}
</script>

<style scoped>

</style>
